import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localStorage/localStorage.service';

@Injectable()
export class TokenReplaceInterceptor implements HttpInterceptor {

  constructor(private _lc: LocalStorageService) { }

  private getReplacementToken(): string {
    // Replace this with your logic to get/generate the new token
    const switchedUserDetails = this._lc.getSwitchedUserDetails();
    return switchedUserDetails?.user_details?.token || '';
  }

  private getOriginalToken(): string {
    // Replace this with your logic to get the original token
    return this._lc.getKey('access_token') || '';
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Only modify GET requests
    // const switchedUserDetails = this._lc.getSwitchedUserDetails();
    // if (request.method === 'GET' && switchedUserDetails) {
    //   const replacementToken = this.getReplacementToken();

    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${replacementToken}`
    //     }
    //   });
    // } else {
    //   // For non-GET requests, use the original token
    //   const originalToken = this.getOriginalToken();

    //   if (originalToken) {
    //     request = request.clone({
    //       setHeaders: {
    //         Authorization: `Bearer ${originalToken}`
    //       }
    //     });
    //   }
    // }

    return next.handle(request);
  }
}
