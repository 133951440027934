export const authenticationApis = {
    login: 'v1/api/auth/login',
    forgotPassword: 'v1/api/auth/forgotPassword',
    resetPassword: 'v1/api/auth/resetPassword',
    decodeForgotPasswordToken: 'v1/api/auth/decode',
    getScanner: 'v1/api/auth/2fa/scanner',
    getVerification: 'v1/api/auth/2fa/verify',
    checkPassword: 'v1/api/auth/checkPassword',
    switchLogin: 'v1/api/auth/switchLogin',
};
