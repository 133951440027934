import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentsService } from 'src/app/core/services/agents/agents.service';
import { CountryStateService } from 'src/app/core/services/country-state/country-state.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  registrationForm: FormGroup;
  country: any;
state: any;
selectedCountry: any;
states: any;
selectedState: any;
cities: any;
selectedCity: any;
constructor(private fb: FormBuilder, private AgentsService: AgentsService,
  private CountryStateService:CountryStateService){}

ngOnInit(): void {
  this.agentSignUp();
  this.countryState();
}

agentSignUp(){
  console.log('agentSignUp called');
  this.registrationForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    confirmEmail: ['', [Validators.required]],
    mobile: ['', Validators.required],
    whatsappNumber: [''],
    company: ['', Validators.required],
    webSite: [''],
    address1: ['', Validators.required],
    country: ['', Validators.required],
    state: ['', Validators.required],
    city: ['', Validators.required],
    postcode: ['', Validators.required],
    //  password: ['', Validators.required],
      // confirmPassword: ['', [Validators.required]]
  },
  { validator: [this.passwordMatchValidator, this.emailMatchValidator] });
}

// passwordMatchValidator(form: FormGroup) {
//   const password = form.get('password').value;
//   const confirmPassword = form.get('confirmPassword').value;

//   return password === confirmPassword ? null : { passwordMismatch: true };
// }

passwordMatchValidator(form: FormGroup) {
  const password = form.get('password').value;
  const confirmPassword = form.get('confirmPassword').value;

  if (password === confirmPassword) {
      form.get('confirmPassword').setErrors(null);
  } else {
      form.get('confirmPassword').setErrors({ passwordMismatch: true });
  }
}

emailMatchValidator(form: FormGroup) {
  const email = form.get('email').value;
  const confirmEmail = form.get('confirmEmail').value;

  if (email === confirmEmail) {
      form.get('confirmEmail').setErrors(null);
  } else {
      form.get('confirmEmail').setErrors({ emailMismatch: true });
  }
}
onSubmit() {
    const formData = this.registrationForm.value;
    const payload = {
      generalDetails: {
        "firstName": formData?.firstName,
        "lastName": formData?.lastName,
        "email": formData?.email,
        "mobile": formData?.mobile,
        "whatsappNumber": formData?.whatsappNumber || '',
        "company": formData?.company,
        "webSite": formData?.webSite || '',
        "address1": formData?.address1,
        "city": formData?.city,
        "postcode": formData?.postcode,
        "country": formData?.country?.country,
        "state": formData.state?.name,
        "status": true,
        // tenantId: "1697182437"
      },
      credentials: {
        // "email": "Agents@example2.com",
        // "password": formData.password
        // createdBy: {
        //   createdId: "653b6ec091e06b21f2c7a58a"
        // }
      },
      status: true
      // createdId: "653b6ec091e06b21f2c7a58a",
      // status: true 
    };


    this.AgentsService.postAgents(payload).subscribe(
      (response) => {
        console.log('Registration successful:');
      },
      (error) => {
        console.error('Registration failed:', error);
      }
    );
  // }
}


private async countryState() {
  try {
    const countryResponse = await this.CountryStateService.getCountries().toPromise();
    this.country = countryResponse?.data;
  } catch (error) {
    console.error('An error occurred while fetching countries:', error);
  }
}

onCountryChange() {
  if (this.selectedCountry) {
    const countryIso2 = this.selectedCountry?.country;

    this.CountryStateService.getStateByCountry(countryIso2).subscribe((resp) => {
      this.states = resp?.data?.states;
      this.selectedState = null;
      this.selectedCity = null;
    });
  }
}

onStateChange() {
  if (this.selectedState) {
    // this.CountryStateService.getCitiesByStateName(this.selectedCountry?.country).subscribe((resp) => {
    //   this.cities = resp?.data;
    //   this.selectedCity = null;
    // });
  }
}
}
